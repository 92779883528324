import { Box, Grid, Typography } from '@material-ui/core';

function Notice({ children }: { children: React.ReactNode }) {
  return (
    <Box mt={20}>
      <Grid container justify="center" alignItems="center">
        <Typography variant="h6">{children}</Typography>
      </Grid>
    </Box>
  );
}

export default Notice;
