import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import { OAuthCallbackRoute, PrivateRoute, useAuthentication } from '@onemedical/auth';
import MicroFrontend from '@onemedical/micro-frontend';
import HomePage from './pages/HomePage';
import LogoutPage from './pages/LogoutPage';
import Notice from './components/Notice';
import AppBar from './components/AppBar';
import apps from './apps.json';

function assetsUrlFor(appConfig: any) {
  const assetsUrl = new URL(window.location.origin);
  if (process.env.NODE_ENV === 'development') {
    assetsUrl.port = appConfig.localPort.toString();
  } else {
    assetsUrl.pathname = appConfig.path;
  }
  return assetsUrl.toString();
}

function App() {
  const [, { authenticating, error }] = useAuthentication();

  return (
    <BrowserRouter>
      <OAuthCallbackRoute />
      {authenticating || error ? (
        <Notice>{error || 'Checking credentials . . .'}</Notice>
      ) : (
        <>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/" component={AppBar} />
          <Route path="/auth/logout" component={LogoutPage} />
          <PrivateRoute path="/home" component={HomePage} />
          {Object.entries(apps).map((app) => (
            <Route
              key={app[0]}
              path={app[1].path}
              render={({ history }) => (
                <MicroFrontend
                  id={app[0]}
                  host={assetsUrlFor(app[1])}
                  history={history}
                  onError={(err) => {
                    console.error(`Failed to load '${app[0]}' app`, err); // eslint-disable-line no-console
                  }}
                >
                  <Notice>{error || 'Loading . . .'}</Notice>
                </MicroFrontend>
              )}
            />
          ))}
        </>
      )}
    </BrowserRouter>
  );
}
export default App;
