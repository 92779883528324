/* eslint-disable no-console */
import { render } from 'react-dom';
import { AuthenticationProvider } from '@onemedical/auth';
import createAuthenticatedHttpLink from '@onemedical/graphql-auth';
import { from, ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createGenerateClassName, CssBaseline, StylesProvider } from '@material-ui/core';
import App from './App';
import apps from './apps.json';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    ));
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, createAuthenticatedHttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_URL })]),
});

const scope = Array.from(new Set(
  Object.entries(apps).reduce(
    (acc, app) => ((app[1] as any).oauthScope ? acc.concat((app[1] as any).oauthScope.split(' ')) : acc),
    ['openid', 'profile', 'email'],
  ),
)).join(' ');

const generateClassName = createGenerateClassName({
  seed: 'management-container',
});

render(
  <AuthenticationProvider
    providerUrl={process.env.REACT_APP_AUTH_PROVIDER_URL!}
    clientID={process.env.REACT_APP_AUTH_CLIENT_ID!}
    audience="https://onemedical.com"
    scope={scope}
    callbackPath="/oauth"
    auth0Connection={process.env.REACT_APP_AUTH0_CONNECTION}
    auth0LogoutReturnTo={`${window.location.origin}/auth/logout`}
  >
    <ApolloProvider client={client}>
      <StylesProvider generateClassName={generateClassName}>
        <CssBaseline />
        <App />
      </StylesProvider>
    </ApolloProvider>
  </AuthenticationProvider>,
  document.getElementById('root'),
);
