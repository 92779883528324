import { gql, useQuery } from '@apollo/client';
import { useAuthentication } from '@onemedical/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons';
import {
  AppBar as MuiAppBar,
  IconButton,
  Grid,
  Link,
  SvgIcon,
  Toolbar,
  Typography,
} from '@material-ui/core';

const getProfile = gql`
  query GetProfile {
    profile {
      id
      displayName
    }
  }
`;

function AppBar() {
  const [logout, { authenticated }] = useAuthentication();
  const { data } = useQuery(getProfile, { skip: !authenticated });

  return (
    <MuiAppBar position="sticky">
      <Toolbar>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Link href="/home" color="inherit">
              <IconButton edge="start" color="inherit">
                <SvgIcon viewBox="0 0 92.1 92.1">
                  { /* eslint-disable-next-line max-len */ }
                  <path d="M11.5,34.6A11.42,11.42,0,0,0,0,46,11.65,11.65,0,0,0,11.5,57.6,11.59,11.59,0,0,0,23.1,46.1h0A11.59,11.59,0,0,0,11.5,34.6Zm34.6,0A11.5,11.5,0,1,0,57.6,46.1h0A11.5,11.5,0,0,0,46.1,34.6Zm0,34.5A11.5,11.5,0,1,0,57.6,80.6h0A11.57,11.57,0,0,0,46.1,69.1ZM46.1,0A11.5,11.5,0,1,0,57.6,11.5h0A11.57,11.57,0,0,0,46.1,0ZM80.6,34.6A11.5,11.5,0,1,0,92.1,46.1,11.5,11.5,0,0,0,80.6,34.6Z" />
                </SvgIcon>
              </IconButton>
            </Link>
          </Grid>
          { authenticated && (
            <Grid item>
              <Typography>
                {data && data.profile.displayName}
                &nbsp;&nbsp;
                { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                <Link href="#" onClick={logout} color="inherit">
                  <FontAwesomeIcon icon={faSignOut} />
                </Link>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppBar;
