import { Box, Container, Grid, Link, Paper, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/pro-regular-svg-icons';
import apps from '../apps.json';

const HomePage = () => (
  <Container>
    <Box py={5}>
      <Typography variant="h4">Management Console</Typography>
      <Paper>
        <Box p={5} mt={5}>
          <Grid container spacing={3}>
            { Object.entries(apps).map((app) => (
              <Grid key={app[0]} item xs={6} md={3}>
                <FontAwesomeIcon icon={(Icons as { [key: string]: any })[app[1].icon]} size="lg" />
                &nbsp;&nbsp;
                <Link href={app[1].path} underline="hover">{app[1].description}</Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </Box>
  </Container>
);

export default HomePage;
